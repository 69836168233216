import moment from "moment";
import { useRouter } from "next/router";
import fileSaver from "file-saver";
import { JSONCrush, JSONUncrush } from "third_party/JSONCrush";
import Pluralize from "pluralize";
import _ from "lodash";

/**
 * Stringify an object into a URL safe query string
 */
export function encodeForQS(data: any) {
  return JSONCrush(JSON.stringify(data));
}

/**
 * Parse a query string into an object
 */
export function decodeFromQS(encoded: string) {
  if (!encoded) return;
  return JSON.parse(JSONUncrush(decodeURIComponent(encoded)));
}

/**
 * @returns The query string parsed to an object, with companyID and projectID REMOVED.
 */
export function getQS() {
  const router = useRouter();
  const query = { ...router.query };
  delete query["companyID"];
  delete query["projectID"];
  return query;
}

/**
 * @returns companyID and projectID from URL
 */
export function getCompanyIDAndProjectID() {
  const router = useRouter();
  const companyID = router.query.companyID as Optional<string>;
  const projectID = router.query.projectID as Optional<string>;
  return { companyID, projectID };
}

/**
 * Returns the base path for a company and project (if a project is selected)
 */
export function getBasePath() {
  const { companyID, projectID } = getCompanyIDAndProjectID();
  if (projectID) {
    return `/company/${companyID}/project/${projectID}`;
  }
  return `/company/${companyID}`;
}

/**
 * "Toggles" an item in an array.
 * ADDS the item if it does not exist in the array.
 * REMOVES the item if it exists in the array.
 * @returns Cloned array, with the element added or removed.
 */
export function addOrRemove<T>(array: T[], item: T) {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  } else {
    return [...array, item];
  }
}

/**
 * Recursively test for an empty object.
 * @param obj Object to be tested
 * @returns true if empty, else false
 */
export function isEmptyObject(obj: AnyObject) {
  if (!obj) return true;
  return obj.constructor.name === "Object"
    ? Object.keys(obj).reduce((x, y) => x && isEmptyObject(obj[y]), true)
    : obj.length == 0;
}

/**
 * Similar to React's useState() hook, but stores state by mutating the query string.
 * @example [name, setName] = useStatefulURL('usersName')  // NOTE: 'usersName' is the key used in the query string (NOT the initial value)
 * @param key key used in querystring
 * @returns [value, setValue]
 */
export function useStatefulURL(key: string): [Optional<string>, (nextValue: Optional<string>) => void] {
  const router = useRouter();
  const value = router.query[key] as Optional<string>;
  const setValue = (nextValue: Optional<string>) => {
    const { pathname, query } = router;
    if (nextValue) {
      query[key] = nextValue;
    } else {
      delete query[key];
    }
    router.push({ pathname, query });
  };
  return [value, setValue];
}

/**
 * Sorts by date, descending unless the ascending parameter is true
 * @param arr Array of objects
 * @param key Key to find the date in the object
 * @param ascending If set to true, sorts in ascending order
 */
export function dateSort<T extends AnyObject>(arr: T[], key: string, ascending?: boolean) {
  arr.sort((a, b) => {
    if (moment(a[key]).isAfter(moment(b[key]))) return ascending ? 1 : -1;
    else if (moment(a[key]).isBefore(moment(b[key]))) return ascending ? -1 : 1;
    else return 0;
  });
}

/**
 * @returns if Mixpanel's ENV variable has been set, true.  Else, false.
 */
export function isMixpanelEnabled() {
  return typeof process.env.NEXT_PUBLIC_MIXPANEL_TOKEN !== "undefined";
}

/**
 * When used in a page's getStaticProps(), requires ENABLE_DADO_ENV=yes to be set at build time for the page to be built.
 * Otherwise, the page will not be built and the route will 404.
 *
 * @example
 * export const getStaticProps = async () => {
 *   return getStaticProps_requireDadoDevtoolsToBeEnabled();
 * };
 */
export function getStaticProps_requireDadoDevtoolsToBeEnabled() {
  if (process.env.ENABLE_DADO_DEVTOOLS !== "yes") {
    return {
      notFound: true, // https://nextjs.org/blog/next-10#notfound-support
    };
  }
  // next is expecting a props object, so return an empty one:
  return { props: {} };
}

/**
 * Saves the given text as a file on our user's computer.
 * @param filename File name.
 * @param mimetype Mimetype.  E.g., `text/csv`.  `;charset=utf-8` is always appended.
 * @param text Data to be saved.
 */
export function saveTextAsFile(filename: string, mimetype: string, text: string) {
  const blob = new Blob([text], { type: mimetype + ";charset=utf-8" });
  fileSaver.saveAs(blob, filename);
}

/**
 * Returns true if the given character is a letter or a number, else false
 */
export function isLetterOrNumber(char: string) {
  return /^[a-z0-9]$/i.test(char);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function searchInObjectsKeys<T extends object>(
  obj: T[],
  searchTerm: string,
  keys?: RecursiveKeyOf<T>[],
  caseSensitive = false
): T[] {
  if (!caseSensitive) {
    searchTerm = searchTerm.toLowerCase();
  }
  searchTerm = caseSensitive ? searchTerm : searchTerm.toLowerCase();
  return obj.filter((item) => {
    const objKeys = typeof keys !== "undefined" ? keys : Object.keys(item);
    return objKeys.some((key) => {
      const value = _.get(item, key);
      const str = caseSensitive ? value : String(value).toLowerCase();
      return str.includes(searchTerm);
    });
  });
}

// TODO check whether the below functions are used:

export const mapToObject = (map: Map<string, any>) => {
  const obj: any = {};
  map.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

export const indicateIOSDevice = () => {
  const userAgentSettings = window.navigator.userAgent.toLowerCase();
  return (
    userAgentSettings.indexOf("iphone") > -1 ||
    userAgentSettings.indexOf("ipad") > -1 ||
    (userAgentSettings.indexOf("macintosh") > -1 && "ontouchend" in document)
  );
};

export const indicateAndroidDevice = () => {
  const userAgentSettings = window.navigator.userAgent.toLowerCase();
  return userAgentSettings.indexOf("android") > -1;
};

export const indicateMobileDevice = () => {
  return indicateIOSDevice() || indicateAndroidDevice();
};

export const isIsoDate = (value: NullableAndOptional<string>) => {
  if (value) {
    return Date.parse(value.toString());
  }
  return false;
};

export const toDefaultDate = (isoDate?: NullableAndOptional<string>, defaultToday = false) => {
  const FORMAT = "MMM DD, YYYY";
  if (isoDate && isIsoDate(isoDate)) {
    return moment(isoDate as string).format(FORMAT);
  } else if (defaultToday) {
    return moment().format(FORMAT);
  }
};

export const ellipsis = (value: string, length = 75): string => {
  value = value || "";
  const suffix = value.length > length ? " ..." : "";
  return `${value.substring(0, length)}${suffix}`;
};

/**
 * Wrapper around proper english accurate pluralizer
 * You can give it any word, even if its already plural.
 * Pass true to 3rd parameter to also output the number too.
 */
export const pluralize = (
  phrase: string, // anything
  count: number,
  outputNumber?: boolean
): string => {
  if (Pluralize.isPlural(phrase)) {
    phrase = Pluralize.singular(phrase);
  }
  return Pluralize(phrase, count, outputNumber); // real pluralizer
};

// Inspired by: https://gitlab.com/projectdado/code/web-app/-/merge_requests/54#note_485723950
export const omitDeep = (obj: any, key: string) => {
  const keys = Object.keys(obj);
  const newObj: any = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) {
        newObj[i] = omitDeepArrayWalk(val, key);
      } else if (typeof val === "object" && val !== null) {
        newObj[i] = omitDeep(val, key);
      } else {
        newObj[i] = val;
      }
    }
  });
  return newObj;
};

const omitDeepArrayWalk = (arr: any, key: string) => {
  return arr.map((val: any) => {
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key);
    } else if (typeof val === "object" && val !== null) {
      return omitDeep(val, key);
    }
    return val;
  });
};

interface HasName {
  name: string;
}

export const sortByName = (a: HasName, b: HasName) => {
  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
};

/**
 * Displays string with elipses if over a certain length
 * @param str
 * @param maxLength
 */
export function strMaxLengthWithElipses(str: string, maxLength: number) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "...";
  }
  return str;
}

export function toPercent(num: number, denom: number): string {
  if (denom === 0) {
    return "0%";
  } else {
    return `${Math.round((num * 100) / denom)}%`;
  }
}

// Inspired by https://stackoverflow.com/questions/1189128/regex-to-extract-subdomain-from-url
export const extractSubdomain = (val: string): string => {
  /* eslint-disable */
  const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
  const match = subdomainRegex.exec(val);
  return match && match.length > 1 ? match[1] : "";
};
